body {
	background-color: #f4f4f487;
	touch-action: pan-y;
}

.btnnn {
	cursor: pointer;
}

.signature {
	display: block;
	width: 100%;
	height: 200px;
	background-color: white;
	border-radius: 10px;
}

.react-modal-sheet-container {
	border-top-left-radius: 20px !important;
	border-top-right-radius: 20px !important;
	height: auto !important;
	max-height: 80%;
}

.react-modal-sheet-backdrop {
	backdrop-filter: blur(3px);
}

.Toastify__toast-container--bottom-center {
	width: 100% !important;
	bottom: 0px !important;
	padding: 20px !important;
}

.Toastify__toast {
	padding: 15px !important;
	border-radius: 10px !important;
	background-color: #2C2C2C !important;
}

.Toastify__close-button {
	align-self: auto !important;
}

.react-select__input:focus {
	box-shadow: none;
}

.loader {
	width: fit-content;
	display: flex;
	align-items: center;
	justify-items: center;
	font-weight: bold;
	font-family: monospace;
	font-size: 20px;
	background: radial-gradient(circle closest-side, #000 94%, #0000) right/calc(200% - 1em) 100%;
	animation: l24 1s infinite alternate linear;
}

.loader::before {
	content: "Loading...";
	line-height: 1em;
	color: #0000;
	background: inherit;
	background-image: radial-gradient(circle closest-side, #fff 94%, #000);
	-webkit-background-clip: text;
	background-clip: text;
}

@keyframes l24 {
	100% {
		background-position: left
	}
}

.progress-loader {
	left: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
}

.progress-loader {
	width: 91%;
	background: rgba(236, 236, 238, 0.253);
	height: 3px;
	border-radius: 7px;
}

.progress {
	content: '';
	width: 1px;
	height: 3px;
	border-radius: 7px;
	background: #FEAE02;
	transition: 0.5s;
	animation: loading1274 2s ease infinite;
}

@keyframes loading1274 {
	0% {
		width: 0%;
	}

	10% {
		width: 10%;
	}

	50% {
		width: 40%;
	}

	60% {
		width: 60%;
	}

	100% {
		width: 100%;
	}
}



.loaderss {
	--ballcolor: #FEAE02;
	--shadow: 0px 0 #ffffff00;
	--shadowcolor: #ffffff00;
	width: 10px;
	height: 10px;
	left: 50px;
	border-radius: 50%;
	position: relative;
	color: var(--ballcolor);
	animation: shadowRolling 2s linear infinite;
}

@keyframes shadowRolling {

	0% {
		box-shadow: var(--shadow),
			var(--shadow),
			var(--shadow),
			var(--shadow);
	}

	12% {
		box-shadow: 100px 0 var(--ballcolor),
			var(--shadow),
			var(--shadow),
			var(--shadow);
	}

	25% {
		box-shadow: 110px 0 var(--ballcolor),
			100px 0 var(--ballcolor),
			var(--shadow),
			var(--shadow);
	}

	36% {
		box-shadow: 120px 0 var(--ballcolor),
			110px 0 var(--ballcolor),
			100px 0 var(--ballcolor),
			var(--shadow);
	}

	50% {
		box-shadow: 130px 0 var(--ballcolor),
			120px 0 var(--ballcolor),
			110px 0 var(--ballcolor),
			100px 0 var(--ballcolor);
	}

	62% {
		box-shadow: 200px 0 var(--shadowcolor),
			130px 0 var(--ballcolor),
			120px 0 var(--ballcolor),
			110px 0 var(--ballcolor);
	}

	75% {
		box-shadow: 200px 0 var(--shadowcolor),
			200px 0 var(--shadowcolor),
			130px 0 var(--ballcolor),
			120px 0 var(--ballcolor);
	}

	87% {
		box-shadow: 200px 0 var(--shadowcolor),
			200px 0 var(--shadowcolor),
			200px 0 var(--shadowcolor),
			130px 0 var(--ballcolor);
	}

	100% {
		box-shadow: 200px 0 var(--shadowcolor),
			200px 0 var(--shadowcolor),
			200px 0 var(--shadowcolor),
			200px 0 var(--shadowcolor);
	}
}

.leap-frog {
	--uib-size: 40px;
	--uib-speed: 2s;
	--uib-color: rgb(255, 255, 255);
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: var(--uib-size);
	height: var(--uib-size);
}

.leap-frog__dot {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 50%;
}

.leap-frog__dot::before {
	content: '';
	display: block;
	height: calc(var(--uib-size) * 0.22);
	width: calc(var(--uib-size) * 0.22);
	border-radius: 50%;
	background-color: var(--uib-color);
	will-change: transform;
}

.leap-frog__dot:nth-child(1) {
	animation: leapFrog var(--uib-speed) ease infinite;
}

.leap-frog__dot:nth-child(2) {
	transform: translateX(calc(var(--uib-size) * 0.4));
	animation: leapFrog var(--uib-speed) ease calc(var(--uib-speed) / -1.5) infinite;
}

.leap-frog__dot:nth-child(3) {
	transform: translateX(calc(var(--uib-size) * 0.8)) rotate(0deg);
	animation: leapFrog var(--uib-speed) ease calc(var(--uib-speed) / -3) infinite;
}

@keyframes leapFrog {
	0% {
		transform: translateX(0) rotate(0deg);
	}

	33.333% {
		transform: translateX(0) rotate(180deg);
	}

	66.666% {
		transform: translateX(calc(var(--uib-size) * -0.4)) rotate(180deg);
	}

	99.999% {
		transform: translateX(calc(var(--uib-size) * -0.8)) rotate(180deg);
	}

	100% {
		transform: translateX(0) rotate(0deg);
	}
}

.xyz {
	font-size: 16px;
}

@supports (-webkit-overflow-scrolling: touch) {
	.xyz_12 {
		font-size: 16px;
	}
}

#toolbar-wrap {
	position: sticky;
	top: 0px;
	height: 60px;
	width: 100%;
}

#toolbar {
	position: absolute;
	left: 0px;
	right: 0px;
	/* padding-left: 15px; */
	padding-top: 10px;
	padding-bottom: 10px;
	/* background-color: red; */
}

#toolbar.down {
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 500ms;
}