.app-screen {
	background: #FDF8F7;
	position: relative;
}

.app-screen::before {
	content: '';
	position: absolute;
	top: -200px;
	right: -200px;
	width: 600px;
	height: 600px;
	background: radial-gradient(#fdd3b7 0, transparent 70%);
}

.app-screen::after {
	content: '';
	position: absolute;
	top: -150px;
	left: -150px;
	width: 400px;
	height: 500px;
	background: radial-gradient(#3a52bb60 0,
			transparent 70%);
}

.app-screen.no-shades::before,
.app-screen.no-shades::after {
	display: none;
}

.customPurple {
	background-color: '#544FA8'
}