/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

#overlay {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	align-items: center;
	justify-content: center;
	backdrop-filter: blur(5px);
  z-index: 999999999999;
}

#overlay img {
	max-width: 80%;
	max-height: 80%;
	border: 2px solid white;
	border-radius: 5px;
  z-index: 9999999999;
}

#close-btn {
	position: absolute;
	top: 0px;
	right: 10px;
	cursor: pointer;
	color: white;
	font-size: 40px;
}